import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./free-session.css";

export default function FreeSession() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div id="logo-wrapper">
        <NavLink exact to="" title="Claim your free 60 minute mentor session">
          <img
            src={`${
              process.env.PUBLIC_URL + "/assets/images/logo-transparent.png"
            }`}
            id="logo"
            alt="logo"
          />
        </NavLink>
      </div>
      <div className="apply-threshold">
        <div>
          <span className="emphasis">Claim</span> Your{" "}
          <span className="emphasis">Free</span> <br />
          <span className="emphasis"> 1 on 1</span>
        </div>
      </div>
      <div className="apply-form-wrapper">
        <iframe
          title="Free Session Application"
          src="https://docs.google.com/forms/d/e/1FAIpQLSclCbjuIymNZnpbIFfZlEDIuaGTmciwuAwZ8ZIrnhv-GV6AQg/viewform?embedded=true"
          width="100%"
          height="2250"
          className="apply-form"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
}
