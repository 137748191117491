import "./info-block.css";

interface InfoBlockProps {
  heading: string;
  body: string;
}

export default function InfoBlock(props: InfoBlockProps) {
  const { heading, body } = props;

  return (
    <div className="info-block">
      <div className="info-block-heading">{heading}</div>
      <div className="info-block-body">{body}</div>
    </div>
  );
}
