import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./tech-talks.css";

export default function TechTalks() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div id="logo-wrapper">
        <NavLink exact to="" title="Claim your free 60 minute mentor session">
          <img
            src={`${
              process.env.PUBLIC_URL + "/assets/images/logo-transparent.png"
            }`}
            id="logo"
            alt="logo"
          />
        </NavLink>
      </div>
      <div id="tech-talk-threshold">
        <div>
          <span className="emphasis">Tech Lead</span> Talks
          <br />
          powered by <span className="emphasis">you</span>
        </div>
        <img
          src={`${process.env.PUBLIC_URL + "/assets/images/lines.png"}`}
          id="threshold-lines"
          alt="lines"
        />
        <a
          href="https://calendar.google.com/event?action=TEMPLATE&tmeid=MDQ0Z2w5NWNoMTlqY2sxcjYzb3Q2ZG84M3NfMjAyMTA3MjBUMDEwMDAwWiBjX25lbGNtZnE2cDA1NmliamlqOHFjZ240dmZzQGc&tmsrc=c_nelcmfq6p056ibjij8qcgn4vfs%40group.calendar.google.com&scp=ALL"
          id="add-to-invite-button"
          target="_blank"
          rel="noreferrer"
        >
          Add me to the invite
        </a>
      </div>
      <div className="tech-talk-section-wrapper">
        <div className="tech-talk-section-heading">
          Tech leads from the biggest companies
        </div>
        <img
          src={`${
            process.env.PUBLIC_URL + "/assets/images/tech-lead-logos.png"
          }`}
          id="tech-lead-logos"
          alt="Tech lead logos"
        />
      </div>
      <div className="tech-talk-section-wrapper blue">
        <div className="tech-talk-section-heading white">Your questions</div>
        <img
          src={`${process.env.PUBLIC_URL + "/assets/images/quotes.png"}`}
          id="community-quotes"
          alt="Community quotes"
        />
      </div>
      <div className="tech-talk-section-wrapper">
        <div className="tech-talk-section-heading">Our community</div>
        <img
          src={`${
            process.env.PUBLIC_URL + "/assets/images/community-logos.png"
          }`}
          id="community-logos"
          alt="Community logos"
        />
      </div>
      <div id="tech-talk-cta-section">
        <a
          href="https://calendar.google.com/event?action=TEMPLATE&tmeid=MDQ0Z2w5NWNoMTlqY2sxcjYzb3Q2ZG84M3NfMjAyMTA3MjBUMDEwMDAwWiBjX25lbGNtZnE2cDA1NmliamlqOHFjZ240dmZzQGc&tmsrc=c_nelcmfq6p056ibjij8qcgn4vfs%40group.calendar.google.com&scp=ALL"
          id="add-to-invite-button-bottom-cta"
          target="_blank"
          rel="noreferrer"
        >
          Put it on my calendar!
        </a>
      </div>
    </>
  );
}
