import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./application.css";

import LandingPage from "../landing-page";
import FreeSession from "../free-session";
import Atlas from "../atlas";
import Apollo from "../apollo";
import Zeus from "../zeus";
import { useEffect } from "react";
import TechTalks from "../tech-talks";

export default function Application() {
  useEffect(() => {
    // console.log("Loading!");
  }, []);
  return (
    <Router>
      <div className="application-wrapper">
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/free-session">
            <FreeSession />
          </Route>
          <Route exact path="/atlas">
            <Atlas />
          </Route>
          <Route exact path="/apollo">
            <Apollo />
          </Route>
          <Route exact path="/zeus">
            <Zeus />
          </Route>
          <Route exact path="/tech-talks">
            <TechTalks />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
