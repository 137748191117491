import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./apollo.css";

export default function Apollo() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div id="logo-wrapper">
        <NavLink exact to="" title="Claim your free 60 minute mentor session">
          <img
            src={`${
              process.env.PUBLIC_URL + "/assets/images/logo-transparent.png"
            }`}
            id="logo"
            alt="logo"
          />
        </NavLink>
      </div>
      <div className="apply-threshold">
        <div>
          Ready to level up?
          <br />
          Join the <span className="emphasis">Apollo</span> mentorship program
        </div>
      </div>
      <div className="apply-form-wrapper">
        <iframe
          title="Apollo Application"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfBHVBRScrgUEe8_42tED3isQ7UA8Y9xv8YA_khlJlmIsl3KQ/viewform?embedded=true"
          width="100%"
          height="2350"
          className="apply-form"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
}
