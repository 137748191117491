import { NavLink } from "react-router-dom";

import "./plan-block.css";

type PlanBlockFeature = {
  quantity: string;
  description: string;
};
interface PlanBlockProps {
  name: string;
  price: number;
  info: string;
  features: PlanBlockFeature[];
  buttonText: string;
  buttonLink: string;
  soldOut?: boolean;
  planMessage?: string;
}

export default function PlanBlock(props: PlanBlockProps) {
  const {
    name,
    price,
    info,
    features,
    buttonText,
    buttonLink,
    soldOut = false,
    planMessage,
  } = props;

  return (
    <div className="plan-block">
      <div className="plan-block-heading-row">
        <div className={`plan-block-name`}>
          <span className={`${soldOut && "sold-out"}`}>{name}</span>
          {soldOut && <span className="sold-out-text">NO SPACE LEFT</span>}
          {!soldOut && planMessage && (
            <span className="sold-out-text">{planMessage}</span>
          )}
        </div>
        <div className="plan-block-price">${price}/week</div>
      </div>
      <div className="plan-block-info">{info}</div>
      <div className="plan-block-feature-block">
        {features.map((feature, idx) => (
          <div className="plan-block-feature-row" key={idx}>
            <div className="plan-block-feature-quantity">
              {feature.quantity}
            </div>
            <div className="plan-block-feature-description">
              {feature.description}
            </div>
          </div>
        ))}
      </div>
      <div className="plan-block-action-row">
        <NavLink exact to={buttonLink} className="plan-block-action-button">
          {buttonText}
        </NavLink>
      </div>
    </div>
  );
}
