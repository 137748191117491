import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import InfoBlock from "./components/InfoBlock";
import PlanBlock from "./components/PlanBlock";

import "./landing-page.css";

export default function LandingPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div id="logo-wrapper">
        <NavLink exact to="" title="Claim your free 60 minute mentor session">
          <img
            src={`${
              process.env.PUBLIC_URL + "/assets/images/logo-transparent.png"
            }`}
            id="logo"
            alt="logo"
          />
        </NavLink>
      </div>
      <div id="nav-buttons">
        <NavLink
          exact
          to="tech-talks"
          title="Add our tech talks to your calendar"
          className="nav-button"
        >
          Join our Tech Lead Talks
        </NavLink>
      </div>
      <div id="landing-page-threshold">
        <div id="threshold-verbiage-wrapper">
          <div id="threshold-verbiage-wrapper-top">Learning to code?</div>
          <div id="threshold-verbiage-wrapper-bottom">
            Get <span className="emphasis">mentored</span> by a{" "}
            <span className="emphasis">tech lead</span>
          </div>

          <NavLink
            exact
            to="free-session"
            className="free-session-button"
            title="Claim your free 60 minute mentor session"
          >
            Claim Your Free 1 on 1
          </NavLink>
        </div>
      </div>
      <div className="landing-page-section why-mentor-section">
        <div className="landing-page-section-heading">Why a mentor?</div>
        <InfoBlock
          heading="Get the answers you need"
          body="Instead of wading through forums and videos, ask a senior-level engineer the questions you want answered in real-time, on a call. In most engineering jobs, you have teammates and tech leads to help with tricky problems. Get the same experience - regardless of your skill level."
        />
        <InfoBlock
          heading="Level up where it matters"
          body="It can be hard to know where you are strong and where you need to grow. Having a mentor is like having a coach; you can be guided step-by-step towards becoming a world-class engineer. Have a interview coming up? Practice with developers who are on hiring teams and get practical recommendations to ace your next job."
        />
        <InfoBlock
          heading="Get an unfair advantage"
          body="Why guess what companies want in a developer? Get the inside scoop on what makes you stand out when trying get hired and promoted. Our mentors have worked for some of the most challenging companies in the world; let them show you the ropes so you can spend more time doing what you love."
        />
      </div>
      <div className="landing-page-section what-is-it-like-section">
        <div className="landing-page-section-heading">What's it like?</div>
        <InfoBlock
          heading="A mentor, not a professor"
          body="You've probably already watched the YouTube videos, and scoured the internet for answers. Here, you're teamed up with a person who gets to know you, what you want to achieve and why. You don't need another lecture - you need a real person who is here to invest in you."
        />{" "}
        <InfoBlock
          heading="Practical over theoretical"
          body="Theory is nice and sometimes it can help in an interview. But - what's actually useful in the 'real-world'? You can get answers to the 'why' behind what you're learning and shown examples of how our mentors are using them in their jobs. "
        />{" "}
        <InfoBlock
          heading="A community of tech leads"
          body="In between meeting with your mentor, you will have access to our community of learners and mentors. Any questions you have will be answered thoroughly and thoughtfully. Just like at big tech companies; you can reach out to your circle of experts to get you started. "
        />
      </div>
      <div className="landing-page-section plans-section">
        <div className="landing-page-section-heading">
          Ready to invest in yourself?
        </div>
        <PlanBlock
          name="Atlas"
          planMessage="3 SPOTS LEFT"
          price={73}
          info="Interested in seeing what mentorship could do for you? Atlas is a low-stress way to try out Code Olympus. You can pause or stop at any time."
          features={[
            {
              quantity: "1",
              description: "30 minute weekly group mentorship sessions",
            },
            {
              quantity: "1",
              description: "30 minute weekly 1 on 1 mentorship sessions",
            },
            {
              quantity: "∞",
              description: "Access to our Discord community",
            },
          ]}
          buttonText="Join Atlas Program"
          buttonLink="/atlas"
        />
        <PlanBlock
          name="Apollo"
          planMessage="2 SPOTS LEFT"
          price={181}
          info="Apollo is for those ready to take things to the next level with up to 3 hours on a week with a mentor. You can pause or stop at any time."
          features={[
            {
              quantity: "2",
              description: "60 minute weekly group mentorship sessions",
            },
            {
              quantity: "1",
              description: "60 minute weekly 1 on 1 mentorship sessions",
            },
            {
              quantity: "∞",
              description: "Access to our Discord community",
            },
          ]}
          buttonText="Join Apollo Program"
          buttonLink="/apollo"
        />
        <PlanBlock
          name="Zeus"
          soldOut={true}
          price={379}
          info="Only for those ready to transcend into myth; Zeus allows you up to 5 hours a week to level up your skills. You can pause or stop at any time."
          features={[
            {
              quantity: "3",
              description: "60 minute weekly group mentorship sessions",
            },
            {
              quantity: "2",
              description: "60 minute weekly 1 on 1 mentorship sessions",
            },
            {
              quantity: "∞",
              description: "Access to our Discord community",
            },
          ]}
          buttonText="Join Zeus Waiting List"
          buttonLink="/zeus"
        />
      </div>
    </>
  );
}
